html {
  scroll-behavior: smooth;
}

.slick-dots li button::before {
  color: rgb(176, 176, 176);
}
.slick-dots li.slick-active button::before {
  color: #ffcd12;
}
.slick-dots li {
  display: inline-block;
  margin: 0 4px;
}
.slick-dots li.slick-active div {
  background-color: #ffcd12;
}
.slick-dots li div {
  background-color: rgb(176, 176, 176);
}

.aboutUsSlider .slick-slide {
  width: 80%;
}

.linkage-primary {
  /* Variables */
  --button_radius: 0.5em;
  --button_color: #ffcd12;
  --button_outline_color: #000000;

  font-size: 16px;
  width: 160px;
  border: none;
  border-radius: var(--button_radius);
  background: var(--button_outline_color);
}

.linkage-secondary {
  /* Variables */
  --button_radius: 0.5em;
  --button_color: #fef5e5;
  --button_outline_color: #000000;

  font-size: 16px;
  /* width: 160px; */
  border: none;
  border-radius: var(--button_radius);
  background: var(--button_outline_color);
}

.button_top {
  display: block;
  box-sizing: border-box;
  text-align: center;
  border: 2px solid var(--button_outline_color);
  border-radius: var(--button_radius);
  padding: 0.75em 1.5em;
  background: var(--button_color);
  color: var(--button_outline_color);
  transform: translateY(-0.2em);
  transition: transform 0.1s ease;
}

.linkage-primary:hover .button_top,
.linkage-secondary:hover .button_top {
  /* Pull the button upwards when hovered */
  transform: translateY(-0.33em);
}

.linkage-primary:active .button_top,
.linkage-secondary:active .button_top {
  /* Push the button downwards when pressed */
  transform: translateY(0);
}

.swal2-title {
  font-family: "Poppins";
}

.swal2-close:hover {
  color: #000000;
}

.notFound {
  --button_radius: 0.5em;
  --button_color: #ffcd12;
  --button_outline_color: #000000;

  font-size: 16px;
  width: 60vw;
  border: none;
  border-radius: var(--button_radius);
  background: var(--button_outline_color);
}

.notFound:hover .button_top {
  /* Pull the button upwards when hovered */
  transform: translateY(-0.44em);
}

.notFound:active .button_top {
  /* Push the button downwards when pressed */
  transform: translateY(0);
}

.testimonialSlider .slick-slide {
  /* width: 20% !important; */
  /* margin-left: -1vw; */
  /* margin-right: 0; */
  height: auto;
}

.testimonialSlider .slick-slide .feedbackSlide {
  width: 96% !important;
}

.testimonialSlider .slick-list {
  width: 100%;
}

.carousel {
  position: relative;
  width: 30vw;
  height: 30vw;
  border-radius: 50%;
  overflow: hidden;
  transform: rotate(45deg);
  transition: transform 0.5s ease;
}

@media (max-width: 1700px) {
  .carousel {
    width: 40vw;
    height: 40vw;
  }
}

@media (max-width: 1024px) {
  .carousel {
    width: 50vw;
    height: 50vw;
  }
}

@media (max-width: 768px) {
  .carousel {
    width: 60vw;
    height: 60vw;
  }
}

@media (max-width: 480px) {
  .carousel {
    width: 80vw;
    height: 80vw;
  }
}

.section {
  position: absolute;
  width: 50%;
  height: 50%;
  scale: 0.98;
  background-size: cover;
  background-position: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.section .text {
  transform-origin: center;
  transform: rotate(-45deg);
  transition: transform 0.5s ease-in-out;
}

#section1 {
  top: 0;
  left: 50%;
  background-size: cover;
  background-position: center;
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(58, 58, 58, 0.2)),
    url("../assets/images/gallery-5.png");
}

#section2 {
  top: 50%;
  left: 50%;
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(58, 58, 58, 0.2)),
    url("../assets/images/gallery-4.png");
  background-size: cover;
  background-position: center;
}

#section3 {
  top: 50%;
  left: 0;
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(58, 58, 58, 0.2)),
    url("../assets/images/gallery-3.png");
  background-size: cover;
  background-position: center;
}

#section4 {
  top: 0;
  left: 0;
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(58, 58, 58, 0.2)),
    url("../assets/images/gallery-2.png");
  background-size: cover;
  background-position: center;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 205, 18, 0.4); /* Yellow color with 40% opacity */
  mix-blend-mode: overlay; /* Apply overlay blend mode */
}

.about-gpa .slick-list {
  @apply lg:h-[70vh] h-[50vh] rounded-xl;
}
.about-gpa .slick-track {
  @apply h-[100%];
}

.about-gpa .slick-slide div {
  @apply h-full;
}
